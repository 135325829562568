import React from 'react'
import Service from '../Components/Services/Service'

const ServicePage = () => {
  return (
    <div>
        <Service />
    </div>
  )
}

export default ServicePage