import React from 'react'
import Blog from '../Components/news/Blog.jsx'

const CompanyNews = () => {
  return (
    <>
     <Blog />
    </>
  )
}

export default CompanyNews